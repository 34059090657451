import Vue from 'vue'
import VueRouter from 'vue-router'
import Dl from '../views/Dl/Dl.vue'
import store from '@/store/index'

const Layout = () => import('@/layout')

Vue.use(VueRouter)

const routes = [
    // 首页
    {
        path: "/Dl",
        component: Dl,
        name: "Dl",
        meta: {
            hidden: true
        }
    },
    {
        path: "/",
        component: Layout,
        redirect: "/Sy",
        children: [
            {
                path: "",
                component: () => import("@/views/Sy/Sy.vue"),
                name: "Sy",
                meta: {
                    title: "首页",
                    svgIcon: "sy",
                }
            }
        ]
    },
    // 业绩管理
    {
        path: "/yjgl",
        component: Layout,
        children: [
            {
                path: "",
                component: () => import("@/views/Yjgl/Yjgl.vue"),
                name: "Yjgl",
                meta: {
                    title: "业绩管理",
                    svgIcon: "yjgl",
                }
            }
        ]
    },
    //经营统计
    {
        path: "/jytj",
        component: Layout,
        redirect: "/MemberManage/jytj",
        meta: {
            title: "经营统计",
            elIcon: "yggl"
        },
        children: [
            {
                path: "/Jytj",
                component: () => import("@/views/Jytj/Jytj.vue"),
                name: "Jytj",
                meta: {
                    title: "季度经营统计",
                    svgIcon: "bmgl",
                }
            },
            {
                path: "/Ydddtj",
                component: () => import("@/views/Ydddtj/Ydddtj.vue"),
                name: "Ydddtj",
                meta: {
                    title: "月度订单统计",
                    svgIcon: "example",
                }
            },
            {
                path: "/Ydrytj",
                component: () => import("@/views/Ydrytj/Ydrytj.vue"),
                name: "Ydrytj",
                meta: {
                    title: "月度人员统计",
                    svgIcon: "example",
                }
            },
            {
                path: "/Ydgztj",
                component: () => import("@/views/Ydgztj/Ydgztj.vue"),
                name: "Ydgztj",
                meta: {
                    title: "月度工资统计",
                    svgIcon: "example",
                }
            },
            {
                path: "/Glcbwh",
                component: () => import("@/views/Glcbwh/Glcbwh.vue"),
                name: "Glcbwh",
                meta: {
                    title: "管理成本维护",
                    svgIcon: "cbgl",
                }
            }
        ]
    },
    //项目管理
    {
        path: "/xmgl",
        component: Layout,
        children: [
            {
                path: "",
                component: () => import("@/views/Xmgl/Xmgl.vue"),
                name: "Xmgl",
                meta: {
                    title: "项目管理",
                    svgIcon: "example",
                }
            },
            {
                path: "/xmgl/detail",
                component: () => import("@/views/Xmgl/project/detail.vue"),
                name: "project-detail",
                meta: {
                    title: "项目管理",
                    svgIcon: "example",
                    hidden: true
                }
            },
        ]
    },
    //合同管理
    {
        path: "/Htgl",
        component: Layout,
        children: [
            {
                path: "",
                component: () => import("@/views/Htgl/Htgl.vue"),
                name: "Htgl",
                meta: {
                    title: "合同管理",
                    svgIcon: "htgl",
                }
            }
        ]
    },
    // // 项目成本
    // {
    //   path: "/xmcb",
    //   component: Layout,
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/Xmcb/Xmcb.vue"),
    //       name: "Xmcb",
    //       meta: {
    //         title: "项目成本",
    //         svgIcon: "xmcb",
    //       }
    //     }
    //   ]
    // },

    {
        path: "/dataManage",
        component: Layout,
        redirect: "/dataManage/yjgl",
        meta: {
            title: "供应链信息维护",
            elIcon: "sjgl"
        },
        children: [
            {
                path: "/Khgl",
                component: () => import("@/views/Khgl/khgl.vue"),
                name: "Khgl",
                meta: {
                    title: "客户管理",
                    svgIcon: "khgl",
                }
            },
            {
                path: "/Gysgl",
                component: () => import("@/views/Gysgl/Gysgl.vue"),
                name: "Gysgl",
                meta: {
                    title: "供应商管理",
                    svgIcon: "example",
                }
            },

            // {
            // path: "/Xmgl",
            // component: () => import("@/views/Xmgl/Xmgl.vue"),
            // name: "Xmgl",
            // meta: {
            // title: "项目管理",
            // svgIcon: "example",
            // }
            // },
            // {
            // path: "/Htgl",
            // component: () => import("@/views/Htgl/Htgl.vue"),
            // name: "Htgl",
            // meta: {
            // title: "合同管理",
            // svgIcon: "htgl",
            // }
            // },
        ]
    },
    {
        path: "/MemberManage",
        component: Layout,
        redirect: "/MemberManage/Sygl",
        meta: {
            title: "人力资源维护",
            elIcon: "yggl"
        },
        children: [
            {
                path: "/Bmgl",
                component: () => import("@/views/Bmgl/Bmgl.vue"),
                name: "Bmgl",
                meta: {
                    title: "部门管理",
                    svgIcon: "bmgl",
                }
            },
            {
                path: "/Sygl",
                component: () => import("@/views/Sygl/Sygl.vue"),
                name: "Sygl",
                meta: {
                    title: "社员管理",
                    svgIcon: "example",
                }
            },
            {
                path: "/Xlgl",
                component: () => import("@/views/Xlgl/Xlgl.vue"),
                name: "Xlgl",
                meta: {
                    title: "协力管理",
                    svgIcon: "example",
                }
            },

        ]
    },
    {
        path: "/SetManage",
        component: Layout,
        redirect: "/SetManage/Sd",
        meta: {
            title: "财务数据维护",
            elIcon: "sd"
        },
        children: [
            {
                path: "/Jxgl",
                component: () => import("@/views/Jxgl/Jxgl.vue"),
                name: "Jxgl",
                meta: {
                    title: "业绩目标设定",
                    svgIcon: "example",
                }
            },
            {
                path: "/Jcsj",
                component: () => import("@/views/Jcsj/Jcsj.vue"),
                name: "Jcsj",
                meta: {
                    title: "摊销数据管理",
                    svgIcon: "example",
                }
            },

            {
                path: "/Hlgl",
                component: () => import("@/views/Hlgl/Hlgl.vue"),
                name: "Hlgl",
                meta: {
                    title: "日元汇率设定",
                    svgIcon: "example",
                }
            },
        ]
    },
    // 报销管理
    {
        path: "/ReimbursementManage",
        component: Layout,
        redirect: "/ReimbursementManage/Bxgl",
        meta: {
            title: "报销管理",
            elIcon: "sd"
        },
        children: [
            {
                path: "/Bxgl",
                component: () => import("@/views/Bxgl/Bxgl.vue"),
                name: "Bxgl",
                meta: {
                    title: "报销管理",
                    svgIcon: "htgl",
                }
            }
        ]
    },
    // 考勤管理
    {
        path: "/Manage",
        component: Layout,
        redirect: "/Manage/Kqgl",
        meta: {
            title: "考勤管理",
            elIcon: "yggl"
        },
        children: [
            {
                path: "/Ygdksj",
                component: () => import("@/views/Ygdksj/Ygdksj.vue"),
                name: "Ygdksj",
                meta: {
                    title: "员工打卡数据",
                    svgIcon: "example",
                }
            },
            {
                path: "/ygkqmx",
                component: () => import("@/views/ygkqmx/ygkqmx.vue"),
                name: "ygkqmx",
                meta: {
                    title: "员工考勤明细",
                    svgIcon: "example",
                }
            },
            {
                path: "/ygkqzb",
                component: () => import("@/views/ygkqzb/ygkqzb.vue"),
                name: "ygkqzb",
                meta: {
                    title: "员工考勤总表",
                    svgIcon: "example",
                }
            },

        ]
    },
    // 权限管理
    {
        path: "/Qxgl",
        component: Layout,
        children: [
            {
                path: "",
                component: () => import("@/views/Qxgl/Qxgl.vue"),
                name: "Qxgl",
                meta: {
                    title: "权限管理",
                    svgIcon: "example",
                }
            }
        ]
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    store.commit('changeTitle', to.meta.title);
    next();
})

export default router
