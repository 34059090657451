import Vue from 'vue'

Vue.filter('moneyFilter', function (value) {

    if (String(value).includes("%") || String(value).includes(",")) return value;
    //截取当前数据到小数点后两位
    if (!value) return '';

    if (!isNaN(parseFloat(value)) && isFinite(value)) {
        if (parseInt(value) === parseFloat(value)) {
            // 获取整数部分
            const intPart = Math.trunc(value)
            // 整数部分处理，增加,
            const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
            // 预定义小数部分
            let floatPart = ''
            // 将数值截取为小数部分和整数部分
            const valueArray = value.toString().split('.')
            if (valueArray.length === 2) { // 有小数部分
                floatPart = valueArray[1].toString() // 取得小数部分
                return intPartFormat + '.' + floatPart
            }
            return intPartFormat + floatPart
        } else {
            let realVal = parseFloat(value).toFixed(2);
            // 获取整数部分
            const intPart = Math.trunc(realVal)
            // 整数部分处理，增加,
            const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
            // 预定义小数部分
            let floatPart = ''
            // 将数值截取为小数部分和整数部分
            const valueArray = realVal.toString().split('.')
            if (valueArray.length === 2) { // 有小数部分
                floatPart = valueArray[1].toString() // 取得小数部分
                return intPartFormat + '.' + floatPart
            }
            return intPartFormat + floatPart
        }
    } else {
        // 获取整数部分
        const intPart = Math.trunc(value)
        // 整数部分处理，增加,
        const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        // 预定义小数部分
        let floatPart = ''
        // 将数值截取为小数部分和整数部分
        const valueArray = value.toString().split('.')
        if (valueArray.length === 2) { // 有小数部分
            floatPart = valueArray[1].toString() // 取得小数部分
            return intPartFormat + '.' + floatPart
        }
        return intPartFormat + floatPart
    }
})