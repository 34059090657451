<template>
  <div class="login-main">
    <div class="login-main-left" v-if="!isMobile">
      <img src="@/assets/image/login/left_part.png" />
    </div>
    <div class="login-main-right">
      <div class="top-logo">
        <img src="@/assets/image/login/logo.png" />
        <span>启扬科技（大连）有限公司</span>
      </div>
      <div class="login-seperate"></div>
      <div class="middle-login-content">
        <div>
          <img src="@/assets/image/login/top_title.png" />
        </div>
        <div>
          <el-input
            placeholder="登录邮箱"
            class="login-form-input login-form-email"
            v-model="mail"
          ></el-input>
        </div>
        <div>
          <el-input
            placeholder="登录密码"
            class="login-form-input login-form-password"
            v-model="password"
            show-password
          ></el-input>
        </div>
        <div :class="isMobile ? 'btnContainer' : ''">
          <el-button class="login-button" type="primary" @click="login()"
            >登 录</el-button
          >
        </div>
        <div style="margin-top: 280px; font-size: 14px">
          Copyright @ 2001-2023 Harvest Co., Ltd. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { service } from '@/utils/service';
import '@/assets/css/login.css';
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      mail: '',
      password: '',
      isMobile: false,
    };
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.isMobile = flag == null ? false : true;
    },
    login() {
      service
        .post('/api/auth/login', {
          mail: this.mail,
          password: this.password,
        })
        .then((resp) => {
          if (resp.data.status_code == '10000') {
            localStorage.setItem('token', resp.data.result.access_token);
            Cookies.set('username', resp.data.result.user_name);
            Cookies.set('rolelevel', resp.data.result.role_id);
            Cookies.set(
              'reimbursementrolelevel',
              resp.data.result.reimbursement_role_id
            );
            Cookies.set('member_id', resp.data.result.member_id);
            Cookies.set('userId', resp.data.result.id);
            if (this.isMobile) {
              this.$store.state.app.sidebar.opened = false;
            }
            this.$router.push('/');
          } else {
            this.$message({
              message: resp.data.display_msg,
              center: true,
              duration: 5000,
              type: 'error',
            });
          }
        })
        .catch((err) => {
          // console.log('err', err)
        });
    },
  },
  created() {
    this._isMobile();
  },
};
</script>
<style scoped>
.el-color-picker__icon,
.el-input,
.el-textarea {
  display: inline-block;
  width: 70% !important;
}
@media screen and (max-width: 1200px) {
  .login-main {
    background-image: none !important;
  }
  .btnContainer {
    height: 50px;
  }
  .login-main {
    padding: 0px;
  }
  .top-logo {
    justify-content: center;
  }
  .middle-login-content {
    margin: 0px;
  }
  .login-main-right {
    width: 100%;
  }
  .el-color-picker__icon[data-v-41986645],
  .el-input[data-v-41986645],
  .el-textarea[data-v-41986645] {
    width: 300px !important;
  }
}
</style>
