import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import moment from 'moment/moment'
import './utils/arrayPrototype'
import './utils/filter'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "@/styles/index.scss"
import "@/icons"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPenToSquare, faPeopleGroup } from '@fortawesome/free-solid-svg-icons'

Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.use(ElementUI);
const bus = new Vue()
Vue.prototype.$bus = bus;
library.add(faPenToSquare)
library.add(faPeopleGroup)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.name !== 'Dl' && (localStorage.getItem('token') == null || localStorage.getItem('token') == 'null'))
    {
      next({ name: 'Dl' })
    } else {
      next()
    }
})

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
