<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { service } from '@/utils/service'

export default {
  name: 'App',

  created() {
    // get
   // http://192.168.2.64:3321/api/Controller/getCbglList
    // service.get('/api/v1/getCbglList', {
    //   params: {
    //     "date":"202305",
    //     "member_id":"",
    //     "state":"0",
    //     "position":"",
    //     "team_id":"",
    //     "company_id":"1"
    //   }
    // }).then(resp => {
    //   console.log('data', resp.data)

    // }).catch(err => {
    //   console.log('err', err)
    // })

    // post
    // http://192.168.2.64:3321/api/Controller/saveInsertCbgl
    // service.post('/api/Controller/saveInsertCbgl', {
    //   "team_id":"1",
    //   "member_id":"8",
    //   "date":"202305",
    //   "thirteen_salary":"4",
    //   "performance_money":"3",
    //   "reimbursement":"55",
    //   "happiness_money":"233",
    //   "other_money":"333",
    //   "direct_person_cost":"33",
    //   "amortization":"555",
    //   "final_person_cost":"55555",
    //   "member_expect_work_day":"23",
    //   "member_work_month_rate":"3",
    //   "company_id":"2"
    // }).then(resp => {
    //   console.log('resp', resp)
    // }).catch(err => {
    //   console.log('err', err)
    // })
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}
html {
  min-width: 400px !important;
}
@media screen and (max-width: 600px) {
    .body {
        overflow: hidden !important;
    }
}
</style>
