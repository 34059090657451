import axios from "axios"
import { BASE_URI } from './pathMap';
import { Message } from 'element-ui'
import { get } from "lodash-es"

function createService() {
    const service = axios.create({
        baseURL: BASE_URI,
    });
    // service.defaults.withCredentials = true;

    // 如果已经login，在Header里添加Token
    // const token = localStorage.getItem('token');
    // console.log('createService token:', token)
    // if (token != null) {
    //     service.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    //     service.defaults.headers.common['Accept'] = 'application/json';
    // }

    // 添加请求拦截器
    service.interceptors.request.use(function (config) {
        // 在发送请求之前做些什么
        const token = localStorage.getItem('token');
        if (token != null && token != 'null') {
            config.headers.Authorization = 'Bearer ' + token;
            config.headers.Accept = 'application/json';
        }
        return config;
    }, function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    });

    // 添加响应拦截器
    service.interceptors.response.use((response) => {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        //console.log(response)
        return response;
    }, (error) => {
        const status = get(error, "response.status");
        switch (status) {
            case 400:
                error.message = "请求错误";
                break;
            case 401:
                // Token 过期时，直接退出登录并强制刷新页面（会重定向到登录页）
                localStorage.clear();
                window.location.reload();
                return Promise.reject(error);
            case 403:
                error.message = "拒绝访问";
                break;
            case 404:
                error.message = "请求地址出错";
                break;
            case 408:
                error.message = "请求超时";
                break;
            case 500:
                error.message = "服务器内部错误";
                break;
            case 501:
                error.message = "服务未实现";
                break;
            case 502:
                error.message = "网关错误";
                break;
            case 503:
                error.message = "服务不可用";
                break;
            case 504:
                error.message = "网关超时";
                break;
            case 505:
                error.message = "HTTP 版本不受支持";
                break;
            default:
                break;
        }
        Message.error(error.message)
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        return Promise.reject(error);
    });

    return service;
}

function createRequestFunction(service) {
    return function (config) {
        return service(config)
    }
}

/** 用于网络请求的实例 */
export const service = createService()
/** 用于网络请求的方法 */
export const request = createRequestFunction(service)