const state = {
    title: 'Sy'
}
const mutations = {
    changeTitle(state, title) {
        state.title = title;
    }
}
export default {
    state,
    mutations
}


