import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import title from './modules/title'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        app,
        settings,
        title
    },
    getters
})

export default store
