/**
 * 将数组转为对象
 * @param {*} key Object中key的取值
 * @returns Object对象
 */
Array.prototype.toMap = function (key) {
    let result = {};
    let len = this.length;
    for (let i = 0; i < len; i ++) {
        let item = this[i];
        let keyValue = this[i][key];
        if (!result[keyValue]) {
            result[keyValue] = [];
        }
        result[keyValue].push(item);
    }
    return result;
}

Array.prototype.toMapOnly = function (key) {
    let result = {};
    let len = this.length;
    for (let i = 0; i < len; i ++) {
        let item = this[i];
        let keyValue = item[key];
        result[keyValue] = item;
    }
    return result;
}